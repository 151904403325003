.fixHeaderColor {
    color: black;
    font-weight: bold;
}

.cwRecommendations {
    background-color: #00659D !important;
    color: white !important;
    font-weight: bold !important;
}

.hideTopBorder {
    border-top: none !important;
}

.hideBottomBorder {
    border-bottom: none !important;
}
