/*------- color variables light mode default ----*/

html {
  display: flex;
  align-items: left;
  justify-items: left;
  text-align: left;
  --color-bg: #FCFCFC !important;
  --color-bg-panel: white !important;
  --color-headings: #0077FF !important;
  --color-text: #010811 !important;
  --color-login-dark-panel-font: #010811 !important;
  --color-login-panel-bg-dark: #FFF # !important;
  --color-login-dark-panel: rgba(184, 226, 255, 0.4) !important;
  --color-border-lite: rgba(3, 152, 252, 0.4) !important;
  --color-login-input-content: #010811 !important;
  --color-alertbox-background: rgba(153, 0, 0, 0.4) !important;
  --color-alert-border: rgba(255, 0, 0, 0.9) !important;
  --color-circle-alert: #780c0c !important;
  --color-frame-header: #bd7e0a !important;
  --color-text-blue: #080B63;
  --color-bright-blue: #0E90BA !important;
  --color-primary-light: #010811 !important;
  --color-primary-dark: #B2E2F8 !important;
  --color-pagetitle: #010811 !important;
  --bs-link-color: #0EB0E5 !important;
  --bs-breadcrumb-divider-color: red !important;
  --bs-breadcrumb-divider: red !important;
  --color-frame: #2C3E50 !important;
  --color-cardbox: #fff !important;
  --color-cardheader: #2C3E50 !important;
  --color-interior-field: #fff !important;
  --color-card-font: #010811 !important;
  --color-accordion-button: #fff !important;
  --color-accordion-body: #d8d8d8 !important;
  --color-file-unavailable: #e2e2e2 !important;
  --color-show-details: #f8f8f8 !important;
  --color-quickscrub-box: #709CAF !important;
  --color-menu-cta-background: #709CAF30 !important;

}

/*------- color variables dark mode ----*/

html[data-theme=dark] {
  --color-bg: #010811 !important;
  --color-bg-panel: #0C1C31 !important;
  --color-headings: #3f465e !important;
  --color-text: #EAEAEA !important;
  --color-frame: #131F2C !important;
  --color-login-dark-panel-font: rgba(112, 156, 175, 0.92) !important;
  --color-login-panel-bg-dark: #08111C !important;
  --color-login-dark-panel: rgba(44, 62, 80, 0.42) !important;
  --color-border-lite: rgba(250, 164, 26, 0.4) !important;
  --color-login-input-content: #ffffff !important;
  --color-alertbox-background: rgba(255, 0, 0, 0.25) !important;
  --color-alert-border: rgba(255, 0, 0, 0.5) !important;
  --color-circle-alert: #FF0000 !important;
  --color-frame-header: rgba(250, 164, 26, 0.4) !important;
  --color-pagetitle: #ADD7EA !important;
  --color-text-blue: #709CAF !important;
  --color-bright-blue: #0eb0e5 !important;
  --color-primary-light: #B2E2F8 !important;
  --color-primary-dark: #010811 !important;
  --color-cardbox: background: #131f2c !important;
  --color-interior-field: #232F3E !important;
  --color-card-font: #d8d8d8 !important;
  --color-accordion-button: #131F2C !important;
  --color-accordion-body: #131F2C !important;
  --color-file-unavailable: #979797 !important;
  --color-show-details: #131F2C !important;
  --color-quickscrub-box: #131f2c !important;
  --color-menu-cta-background: #131f2c !important;

}

/*------- color variables constants (for dncscrub baseline colors, built on upload page css) ----*/

:root {
  --color-bullets: #6991A3;
  --color-pagetitle: #6991A3;
  --color-boxshadow: rgba(196, 196, 196, 0.2);
  --color-border-input: #e5e5e5;
  --color-card-bg-dark: rgba(44, 62, 80, 0.42);
  --color-card-bg-light: rgba(184, 208, 217, 0.46);
  --color-white: #fff;
  --color-offwhite: #dadada;
  --color-black: #000;
  --color-border: #709CAF;
  --color-frame: #121F2D;
  --color-card-footer: #f8f8f8;

  /* standard login */
  --color-login-input: #6991A3;
  --color-login-glow: #FAA41A;
  --color-login-delighter: #FAA41A;
  --color-active-login: #53A3DA;
  --color-boxshadow-blue: #6991A3;
  --color-golden-border: rgba(250, 164, 26, 0.8);
  --color-gold: #ffd700 !important;
  --color-golden: #89662F;
  --color-goldenrod: #daa520;
  --color-golden-dark: rgba(137, 102, 47, 0.95);
  /* colors alerts*/
  --color-alert-red: rgba(153, 0, 0, 0.65);
  --color-approved-green: #249249;
  /* GRAY range */
  --color-pale-gray: rgba(249, 249, 249, 0.8);
  --color-gray-100: #F7FAFC;
  --color-gray-200: #EDF2F7;
  --color-gray-300: #E2E8F0;
  --color-gray-400: #CBD5E0;
  --color-gray-500: #A0AEC0;
  --color-gray-600: #718096;
  --color-gray-700: #4A5568;
  --color-gray-800: #2D3748;
  --color-gray-900: #1A202C;
  --bs-heading-color: #dbdbdb !important;
  --cg-navbar-width: 16.875rem;
  --cg-content-margin-left: 18.8rem;
}

/*---- END color variables ----*/

a {
  color: #0EB0E5 !important;
  text-decoration: none;
}

body {
  width: 100%;
  background-color: var(--color-bg);

}

.page-bg {
  background: var(--color-bg);
  color: var(--color-text);
  font-size: 1rem;
  height: 100%;
  padding-right: 0 !important;
  padding-left: 0 !important;
  /* dark: background: #010811 !important; */
}

.topbar {
  background-color: var(--color-frame);
  width: 100%;
  height: 80px;
  flex-shrink: 0;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: row;
  border-bottom: 8px solid rgba(255, 153, 0, 0.40);
  position: absolute;
  z-index: 23;
}

.dnc-logo {
  width: 214px;
  height: 32px;
  padding-top: 16px;
}

.tabbed-nav {
  left: 3px;
  top: 8px;
  position: relative;
  border-radius: 8px;
}

.user-status-icon {
  position: relative;
  left: -6px;
  top: 20px;
}

/* sidebar */

.sidebar {
  height: 100%;
  background-color: var(--color-frame);
  position: fixed !important;
  top: 80px;
  padding-top: 24px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 148px;
}

.account-name {
  font-size: 18px;
  color: white;
  margin-left: 20px;
  margin-top: -12px;
}

/* width */

.sidebar::-webkit-scrollbar {
  width: 10px;
}

/* Track */

.sidebar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px #0A8CB780;
  border-radius: 10px;
  background-color: #131F2C;
}

/* Handle */

.sidebar::-webkit-scrollbar-thumb {
  background: #0A8CB7;
  border-radius: 10px;
}

/* Handle on hover */

.sidebar::-webkit-scrollbar-thumb:hover {
  background: #FF990090;
}

aside {
  padding-left: 0 !important;
  scroll-padding-inline: auto;
}

.account-info {
  top: 18px;
  position: relative;
  left: -18px;
}

.account-info,
.userid {
  line-height: 1.2;
}

.userid {
  color: #dbdbdb;
  top: 17px;
  font-size: 10px;
  position: relative;
}

.logout-dns {
  color: #0A8CB7 !important;
  top: 24px;
  left: 14px !important;
  font-size: 14px;
  position: relative;
}

.page-top-shortcut {
  padding-bottom: 3rem;
  padding-right: 1rem;
  text-align: right;
  padding-top: 1rem;
  position: relative;
}

.top-padding {
  padding-top: 102px;
}

.dropdown-menu {
  --bs-dropdown-bg: #131f2c !important;
  background-color: #232F3E !important;
}

a.dropdown-item:hover {
  color: goldenrod !important;
  background-color: #131f2c !important;
}

a.dropdown-item {
  background-color: #232F3E !important;
  --bs-dropdown-link-active-bg: midnightblue !important;
  --bs-dropdown-link-active-color: goldenrod !important;
}

.nav-pills {
  --bs-nav-pills-link-active-bg: #010811;
}

.nav-tabs .nav-link {
  font-size: 1rem !important;
  border-color: #131F2C !important;
  color: white !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #0EB0E5 !important;
  background-color: #010811 !important;
  height: 64px;
  border: none;
}

a.nav-link.active {
  border-color: #131F2C !important;
}

.nav-tabs,
.nav-tabs .nav-link {
  border-bottom: none !important;
}

ul.campaign-lists {
  list-style-type: none;
  margin-right: 8px;
  line-height: 1.4;
  position: relative;
  left: -10px;
  font-size: 1rem;
  width: 95%;
}

.footer-space {
  padding-bottom: 5.5rem;
}

ul.campaign-lists a {
  color: white !important;
  text-decoration: none;
}

ul.campaign-lists a:hover {
  color: #0A8CB7 !important;
}

.searchbox-fixed {
  z-index: 444;
  bottom: 4%;
  width: 100%;
  padding-bottom: 2rem;
  width: 170px;
  height: 150px;
  margin-bottom: 1.8rem;
}

.add-another-project {
  left: 24px;
  position: relative;
  top: .8rem;
}

.add-project {
  border-radius: 2rem;
  width: 90%;
  height: 45px;
  left: .8rem;
  margin-top: 1rem;
  color: white;
  background-color: #232F3E;
  position: relative;
  border: none;
  outline: none;
  font-size: .8rem;
}

.project-name {
  top: 0;
  position: relative;
  color: white;
  background-color: #232F3E;
}

.submit-button-icon {
  position: relative;
  left: 27%;
}

.submit-button-icon:active {
  box-shadow: 2px 2px 5px #fff;
}

.submit-button {
  width: 2rem;
  height: 2rem;
  color: white;
  background-color: #0EB0E5;
  border-radius: 50%;
}

/* icons */

i.bi-folder2,
.campaign-folder {
  font-size: 1rem;
}

i.bi-folder2 a {
  font-size: 1rem;
  color: var(--color-golden) !important;
  padding-right: .5rem;
}

i.bi-folder2:hover {
  font-size: 1rem;
  color: var(--color-gold);
  padding-right: .5rem;
}

/* checkbox */

#dnc-checkbox label {
  color: var(--color-login-input-content);
  margin-left: 8px;
  padding-right: 16px;
}

#dnc-checkbox label:after {
  background: var(--color-golden) !important;
}

.form-check-input {
  --bs-form-check-bg: var(--color-golden) !important;
  background-color: #232F3E !important;
  padding-right: 1rem !important;
  height: 1.2rem !important;
}

.spaced {
  margin-left: 2px;
  color: #709CAF;
}

/* subnav */



.marg-mobile-top {
  margin-top: 3.5rem !important;
}

/*.marg-tablet-top {
      margin-top: 4rem!important;
    }*/

.subnav-icon {
  padding-right: .5rem;
  color: #0A8CB7 !important;
  font-size: 1.5rem;
  position: relative;
}

.subnav-spacing {
  padding-left: 3rem;
}

.subnav-textstacked {
  display: block;
  position: relative;
  left: 30px;
  top: -21px;
}

.nav-link {
  color: #0A8CB7 !important;
  font-size: 1rem;
  padding-right: 1rem;
}

.dncscrub-subnav a:hover,
.subnav-icon:hover {
  color: #FF990090 !important;
}

img.dncscrub-logo {
  width: 100%;
}

.navbar-toggler {
  border: none;
  color: #0eb0e5 !important;
  margin: 1rem;
}

/* main section */

h1.dns-pagetitle {
  position: relative;
  text-align: left;
  font-size: 2rem !important;
  color: var(--color-text);
}

.upload-list {
  height: 55px;
  color: #010811;
  background-color: #0EB0E5;
  font-weight: 500;
  text-transform: uppercase;
  position: relative;
  border: none;
  outline: none;
  border-radius: 8px;
  font-size: 14px;
  padding: 8px 10px 16px 8px;
  width: 100%;
}

.upload-list:active {
  background-color: var(--color-golden);
}

.upload-list:hover {
  background-color: #0A8CB7 !important;
}

.upload-list-icon {
  font-size: 24px;
  padding-right: .5rem;
}

.download-list-icon {
  font-size: 24px;
  padding-left: .5rem;
}

.bi-person-fill-gear {
  font-size: 1.5em;
  color: #FFD966;
}

.bi-person-fill {
  font-size: 1.5em;
  color: #B984FF;
}

.bi-person-plus-fill {
  font-size: 1.5em;
  color: #DB8113 !important;
}

p.upload-copy {
  position: relative;
  color: var(--color-text);
  font-size: 1rem;
  padding: 0 2rem;
}

.upload-method-status {
  right: 8px;
  position: absolute;
  text-align: end;
}

.upload-card-body {
  font-size: 1rem !important;
  background-color: transparent !important;
}

.card-header {
  color: #dbdbdb;
  background-color: #2C3E50;
}

.upload-card {
  background-color: transparent !important;
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}

.quickscrub-phonebox {
  position: relative;
  top: 1px;
  height: 110px;
  border-radius: 8px;
  border: 1px solid #0A8CB7;
  background: var(--color-cardbox);
  padding: 0.75rem;
}

.quickscrub-icon {
  margin-bottom: .1rem;
  rotate: 252deg;
  font-size: 3rem;
}

.quickscrub-number-submit {
  margin-top: calc(4% - 8px) !important;
  margin-left: calc(90% - 12px) !important;
  display: block;
  position: absolute;
  cursor: pointer;
}

.add-phone-number {
  border-radius: 2rem;
  width: 100%;
  height: 45px;
  margin-top: .8rem;
  color: white;
  background-color: var(--color-interior-field);
  position: relative;
  border: 1px solid #0A8CB7;
  outline: none;
}

.quickscrub-placeholder-copy {
  color: #7C8A99;
  font-size: 1rem;
  font-style: italic;
  margin-left: 1rem;
  margin-top: .2rem;
  width: 60%;
}

.campaign-list-icon-actived {
  color: #DAA520 !important;
  display: inline-block;
}

.campaign-list-icon-actived:hover svg,
.campaign-list-icon-actived:active svg {
  fill: #DAA520 !important;
}

.sidenav-icon-spacer {
  margin-right: .5rem;
}

.top-menu-spacer {
  margin-left: 1rem;
}

/* status cards */

.scrub-status-card {
  margin-bottom: 1rem;
}

.status-card {
  border-radius: 8px !important;
  border: 1px solid #dbdbdb;
  background: var(--color-cardbox);
  color: #fff !important;
  font-size: 1rem;
}

/* status card scrub */

.status-card-scrub {
  border-radius: 8px !important;
  border: 1px solid #B984FF !important;
  background: var(--color-cardbox);
  color: #526981 !important;
  border-bottom: #B984FF !important;
  --bs-card-border-color: #B984FF !important;
  font-size: 1rem;

}

/* status card completed */

.status-card-completed {
  border-radius: 8px !important;
  border: 1px solid #3ADA67 !important;
  background: var(--color-cardbox);
  color: #526981 !important;
  border-bottom: #3ADA67 !important;
  --bs-card-border-color: #3ADA67 !important;
  font-size: 1rem;

}

/* status card importing */

.status-card-import {
  border-radius: 8px !important;
  border: 1px solid #0A8CB7 !important;
  background: var(--color-cardbox);
  color: #526981 !important;
  border-bottom: #0A8CB7 !important;
  --bs-card-border-color: #0A8CB7 !important;
  font-size: 1rem;

}

/* status card queued */

.status-card-queue {
  border-radius: 8px !important;
  border: 1px solid #DB8113 !important;
  background: var(--color-cardbox);
  color: #526981 !important;
  border-bottom: #DB8113 !important;
  --bs-card-border-color: #DB8113 !important;
  font-size: 1rem;

}

/* status card generate */

.status-card-generate {
  border-radius: 8px !important;
  border: 1px solid #FDBB01 !important;
  background: var(--color-cardbox);
  color: #526981 !important;
  border-bottom: #FDBB01 !important;
  --bs-card-border-color: #FDBB01 !important;
  font-size: 1rem;

}

/* status colors */

.status-generate {
  color: #FDBB01;
}

.status-queued {
  color: #DB8113;
}

.status-importing {
  color: #0A8CB7;
}

.status-scrubbing {
  color: #B984FF;
}

.status-done {
  color: #3ADA67;
}

.not-status {
  color: #526981;
}

.status-position {
  position: relative;
  top: -0.5rem;
}

.upload-type {
  right: 24px;
  position: absolute;
}

.card {
  border-bottom: #dbdbdb;
  --bs-card-border-color: #dbdbdb;
}

/* .card-body {
    font-size: 12px;
  } */

.card-footer {
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  background: var(--color-card-footer) !important;
}

.download-file-unavailable {
  background-color: var(--color-file-unavailable);
  height: 40px;
  padding: 0 16px;
  color: #425569;
  border-radius: 8px;
  outline: none;
  border: none;
  font-size: small;
  line-height: 1;
}

.not-allowed {
  cursor: not-allowed;
}

.download-file-available {
  background-color: #0A8CB7;
  height: 40px;
  padding: 0 16px;
  /*color: #131F2C;*/
  color: #fff;
  border-radius: 8px;
  outline: none;
  border: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
}

button.download-file-available:hover {
  background-color: #0EB0E5;
}

button.download-file-available:active {
  background-color: var(--color-golden);
}

.top-margin {
  margin-top: 1rem !important;
  display: flex;
}

.card {
  color: var(--color-text);
}

.show-hide-reveal {
  width: 300px;
  position: relative;
  left: 20%;
  color: #0A8CB7;
}

.accordion-button:focus {
  z-index: 3;
  border: none;
  outline: 0;
  box-shadow: 0 0 0 .25rem #131F2C !important;
  background: var(--color-show-details);
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: none;
  border-top-right-radius: none;
}

.accordion-button:not(.collapsed) {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230EB0E5'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: none;
  border-bottom-left-radius: none;
}

.accordion {
  --bs-accordion-bg: var(--color-accordion-body);
  --bs-accordion-border-color: #131F2C !important;
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230EB0E5'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230EB0E5'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

.accordion-item {
  border: none !important;
  /* border: 1px solid #131F2C !important; */
}

.accordion-button:not(.collapsed) {
  box-shadow: none !important;
}

.accordion-button {
  background-color: var(--color-show-details);
  color: #0A8CB7 !important;
  width: 150px !important;
  padding: 0 !important;
}

.accordion-header {
  background: var(--color-show-details);
}

.accordion-body {
  background: var(--color-show-details);
  color: var(--color-text);
  text-align: left;
  /*padding-left: 0 !important;*/
}

.delete-top-margin {
  display: block;
  margin-top: 8px;
  margin-left: 60%;
}

.hide-space {
  padding-top: 1.5rem;
  padding-left: 1.5rem;
  font-size: .8rem;
}

.icon-space {
  padding-left: .15rem;
}

.status-icon {
  font-size: 1rem;
  font-weight: 700;
}

.status-bar {
  font-size: 1.03rem;
  font-weight: 500;
  color: #709CAF;
}

.scrub-status {
  padding-bottom: 1rem;
  display: block;
  color: #526981;
}

.status-importing {
  font-weight: 700;
  color: #0A8CB7 !important;
  font-size: 1.2rem;
  margin-top: -.5rem;
}

.status-queued {
  font-weight: 700;
  color: #DB8113 !important;
  font-size: 1.2rem;
  margin-top: -.5rem;
}

.status-scrubbing {
  font-weight: 700;
  color: #B984FF !important;
  font-size: 1.2rem;
  margin-top: -.5rem;
}

.status-generating {
  font-weight: 700;
  color: #FDBB01 !important;
  font-size: 1.2rem;
  margin-top: -.5rem;
}

.status-completed {
  font-weight: 700;
  color: #228B22 !important;
  font-size: 1.1rem;
  margin-top: -.5rem;
}

.download-history {
  font-size: smaller;
  display: block;
  margin: .5rem;
}

/* end status cards */

.active-page {
  color: black !important;
}

/* footer */

.footer {
  background-color: #121F2D !important;
  border-top: 8px solid #709CAF !important;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 555 !important;
}

.footer h5 {
  left: 32px;
  position: relative;
  font-size: 16px;
  margin: 8px;
  padding: .71rem;
  color: var(--color-offwhite);
}

.page-width-for-testing,
.page-width-for-dncscrub-desktop {
  width: 100%;
  max-width: 1220px;
}

aside {
  width: 100%;
  position: fixed;
  display: block;
}

.pads {
  padding-top: 100px;
}

.fixer {
  position: fixed;
  width: 100%;
  z-index: 999;
}

.upload-list-container,
.science {
  height: 130px;
  min-width: 844px;
}

main {
  z-index: -1 !important;
}

.footer-behavior {
  position: sticky;
  z-index: 23;
}

button.navbar-toggler {
  border: none !important;
}

.right-position-nav {
  float: right;
}



.hide-inactive-checkbox {
  margin-left: 8px;
  margin-top: 16px;
}

/* width */

.sidebar-scroll::-webkit-scrollbar {
  width: 10px;
}

/* Track */

.sidebar-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px #0a8cb780;
  border-radius: 10px;
  background-color: var(--color-frame);
}

/* Handle */

.sidebar-scroll::-webkit-scrollbar-thumb {
  background: #0a8cb7;
  border-radius: 10px;
}

/* Handle on hover */

.sidebar-scroll::-webkit-scrollbar-thumb:hover {
  background: #ff990090;
}

.sidebar-scrolling-y {
  scroll-padding-inline: auto;
  overflow-y: scroll !important;
  overflow-x: hidden;
}

.menu-position {
  margin-left: 2rem;
  margin-top: 0.5rem;
}

#current-campaign-name {
  margin-top: 2.5rem;
  margin-left: 1.2rem;
  font-size: 1rem;
  line-height: 1;
  color: wheat;
}

nav.navbar.sub-nav.px-5 {
  z-index: 0 !important;
}

nav.breadcrumb-divider {
  color: #526981;
}

li.breadcrumb-item.active {
  color: #526981;
}

.breadcrumb-deliniator {
  color: #709CAF80;
  padding-left: .5em;
  padding-right: .5em;
}



.side-navbar {
  width: 180px;
  height: 100%;
  position: fixed;
  margin-left: -300px;
  background-color: var(--color-frame);
  transition: 0.5s;
}

h5.side-navbar-campaign {
  color: #0eb0e5;
  font-size: 1rem;
  margin-left: 0.62rem;
  margin-top: 0.4rem;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--color-golden) !important;
  opacity: 0.4;
}

.nav-link:focus,
.nav-link:hover {
  background-color: #C0E5F460;
  color: wheat !important;
}

.nav-link:active {
  background-color: #C0E5F460 !important;
  color: var(--color-golden-dark);
}

.active-cont {
  margin-left: 180px;
}

#menu-btn {
  background-color: var(--color-menu-cta-background);
  margin-left: -56px !important;
  font-size: 1.9rem;
  padding: .5rem;
}

.side-slider input {
  border-radius: 2rem;
}

.side-slider {
  transition: 0.4s;
}

.active-nav {
  margin-left: -19px;
}

.navbar-background {
  background: var(--color-frame);
  border-bottom: 8px solid var(--color-golden-dark);
}

* {
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
}

html {
  margin: 0 auto;
  width: 100%;
  background: var(--color-bg);
  color: #fff;
  font-size: 1rem;
  font-family: Roboto, Raleway, "Open Sans", Arial, sans-serif;
  height: -webkit-fill-available;
  min-height: 100vh;
}

header {
  background: #131f2c !important;
  margin: 0 auto;
  width: 100%;
  position: fixed;
  z-index: 900;
}

.marg88 {
  margin-top: 5.5rem;
}

.marg90 {
  margin-top: 5.625rem;
}

/* added to allow for bottom Add Project input to not obscure any side dropdown content */
.pad20 {
  padding-bottom: 20rem;
}

.pad2 {
  padding-bottom: 1.2rem;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: #131f2c !important;
}

.golden-border {
  border-bottom: 8px solid rgba(255, 153, 0, 0.4);
}

.nav-bar {
  background-color: #131f2c !important;
}

.navbar {
  --bs-navbar-padding-y: 0 !important;
}

.nav-item,
.nav-link,
.navbar-toggler-icon {
  /*color: #0eb0e5 !important;*/
}

a,
button,
input[type="submit"] {
  -webkit-transition: all 0.25s linear;
  -moz-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  transition: all 0.25s linear;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: Roboto, Raleway, "Open Sans", Arial, sans-serif;
  font-weight: 300;
  line-height: 1.1;
  color: inherit;
}

a,
a:visited,
a:focus,
a:active,
a:hover {
  outline: 0 !important;
}

ul {
  list-style: none;
}

.animated-area {
  overflow: hidden;
}

.marg18 {
  margin-top: 18px;
}

.marg25 {
  margin-top: 25px;
}

.marg50 {
  margin-top: 50px;
}

.marg75 {
  margin-top: 75px;
}

.marg100 {
  margin-top: 100px;
}

.marg125 {
  margin-top: 125px;
}

.marg150 {
  margin-top: 155px;
}

.pad-mobile-breadcrumb {
  padding-right: 0;
  padding-left: 3rem !important;
  padding-top: 3.8rem !important;

}

.top_line {
  background: #010811;
}

.contact-top li {
  float: left;
  padding: 0 10px;
  line-height: 33px;
  border-right: 1px solid #eee;
  font-size: 1rem;
  font-family: Roboto, Raleway, "Open Sans", Arial, sans-serif;
  font-weight: 300;
}

.contact-top li a {
  color: #fff;
}

.contact-top li:first-child {
  padding-left: 0px;
}

.contact-top li:last-child {
  border-right: 0px;
}

.contact-top li i {
  font-size: 14px;
}

.page_head {
  padding-top: 0px;
  background-color: #010811;
}

.logo {
  position: relative;
  max-width: 120px;
  margin-bottom: .31rem;
  margin-top: 0.4rem;
  margin-left: 0.1rem;
}

nav {
  position: relative;
  left: 0px;
  z-index: 1 !important;
  width: 100%;
}

.sticky {
  position: fixed;
  border-bottom: 1px solid #fff;
  background: rgba(255, 255, 255, 1);
}

.menu ul ul {
  width: auto !important;
  background: #010811 !important;
}

.menu ul ul li {
  width: 180px;
}

.menu ul li.sub li {
  width: 140px;
  border-left: 1px dotted #eee;
}

.menu {
  float: right;
}

.menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.menu ul li {
  position: relative;
  float: left;
}

.menu ul li ul {
  position: absolute;
  left: 0px;
  display: block;
  visibility: hidden;
  width: 100%;
  opacity: 0;
}

.menu ul li:hover ul {
  display: inline-block;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.menu ul li:hover ul,
.menu ul li li:hover ul,
.menu ul li li li:hover ul {
  display: block;
  visibility: visible;
  opacity: 1;
}

.menu ul li a {
  padding: 37px 25px;
  color: #fff;
  text-decoration: none;
  font-weight: 300;
  font-size: 14px;
  font-family: Roboto, Raleway, "Open Sans", Arial, sans-serif;
  display: block;
  -webkit-transition: all 0s ease;
  -moz-transition: all 0s ease;
  -ms-transition: all 0s ease;
  -o-transition: all 0s ease;
  transition: all 0s ease;
}

.menu ul li:last-child a {
  padding-right: 0px;
}

.menu li a:hover {
  outline: none;
  border-color: #00c0e1;
  color: #00c0e1 !important;
  text-decoration: none;
}

.menu li.current a {
  color: #dadada;
}

.menu li:hover a {
  color: goldenrod;
}

.menu ul ul {
  background: #232f3e !important;
}

.menu ul li ul li a {
  padding: 8px 24px;
  border-top: 1px solid rgba(0, 0, 0, 0.08) !important;
  color: #dadada !important;
  font-weight: normal;
  font-size: 1rem;
  font-weight: 300;
  -webkit-backface-visibility: hidden;
}

.menu ul li ul li:first-child a {
  border-top: none !important;
}

.menu ul li ul {
  border-top: 1px solid #00c0e1;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.menu ul li ul li a:hover {
  background-color: #131f2c;
  color: goldenrod !important;
}

nav.sticky .logo {
  margin-top: 10px;
}

nav.sticky ul li a {
  padding: 21px;
  font-weight: 300;
  font-size: 1rem;
}

nav.sticky ul li a:before {
  content: "";
}

.dl-trigger {
  display: none;
}

.dl-back {
  display: none;
}

.menu ul ul ul {
  top: 0px !important;
  left: 180px;
  margin-top: 0px !important;
}

.menu ul li ul li ul {
  visibility: hidden !important;
}

.menu ul li ul li:hover ul {
  visibility: visible !important;
}

.promo-block {
  display: table;
  margin: auto;
}

.userid-icon {
  background: url("https://www.dncscrub.com/images/blue/user-admin.gif") no-repeat;
}

.user-position {
  top: 36px;
  left: 48px;
}

.page-bg {
  display: flex;
  background: #010811;
  color: white;
  font-size: 1rem;
}

.topbar {
  background-color: #131f2c;
  width: 100%;
  height: 80px;
  flex-shrink: 0;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: row;
  border-bottom: 8px solid rgba(255, 153, 0, 0.4);
}

.dncscrub-logo {
  width: 240px;
  height: 32px;
  padding: 10px;
}

.tabbed-nav {
  left: 8px;
  top: 8px;
  position: relative;
}

.sidebar {
  width: 270px;
  height: 720px;
  background-color: #131f2c;
}

#account-collapse,
button.btn-toggle::before {
  color: #0eb0e5 !important;
}

.btn-toggle:hover,
.btn-toggle:focus {
  background-color: #131f2c;
  color: goldenrod !important;
}

.btn.btn-toggle.d-inline-flex.align-items-center.rounded.border-0.nav-link.collapsed {
  background-color: #131f2c50;
  color: #0eb0e5 !important;
}

.campaign-folder {
  font-size: 1.3rem !important;
  color: var(--color-goldenrod);
}

.account-name {
  font-size: 24px;
  color: white;
  margin-left: 20px;
  font-family: Arial, sans-serif;
}

.account-info,
.userid {
  color: #0eb0e5;
  top: 24px;
  position: relative;
  line-height: 1.2;
}

.userid {
  color: #0eb0e5;
  top: 4px;
  left: 120px;
  position: relative;
}

.logout {
  color: #0eb0e5;
  top: 24px;
  left: 172px;
  position: relative;
  font-size: 10px;
  margin-top: 2rem;
}

.nav-tabs .nav-link {
  color: white;
  border-color: #131f2c !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #0eb0e5 !important;
  background-color: #010811 !important;
  border-color: #131f2c !important;
}

a.nav-link.active {
  padding-bottom: 40px;
  border-color: #131f2c !important;
}

.nav-padding {
  margin-left: 1rem;
}

.navbar-nav .dropdown-menu {
  z-index: 9999;
}

.page-bg {
  display: flex;
  background: #010811;
  color: white;
  font-size: 16px;
}

.topbar {
  background-color: #131f2c;
  width: 100%;
  height: 80px;
  flex-shrink: 0;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: row;
  border-bottom: 8px solid rgba(255, 153, 0, 0.4);
}

.dncscrub-logo {
  width: 240px;
  height: 32px;
  padding: 10px;
}

.tabbed-nav {
  left: 8px;
  top: 8px;
  position: relative;
}

.sidebar {
  width: 270px;
  height: 720px;
  background-color: #131f2c;
}

.account-name {
  font-size: 24px;
  color: white;
  margin-left: 20px;
  font-family: Arial, sans-serif;
}

.account-info,
.userid {
  color: #0eb0e5;
  top: 24px;
  left: 40px;
  position: relative;
  line-height: 1.2;
}

.userid {
  color: #0eb0e5;
  top: 4px;
  left: 120px;
  position: relative;
}

.logout {
  color: #0eb0e5;
  top: 24px;
  left: 172px;
  position: relative;
}

.nav-tabs .nav-link {
  color: white;
  border-color: #131f2c !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #0eb0e5 !important;
  background-color: #010811 !important;
  border-color: #131f2c !important;
}

a.nav-link.active {
  padding-bottom: 40px;
  border-color: #131f2c !important;
}

@media screen and (max-width: 1200px) {
  .animated {
    -webkit-animation: 0 !important;
    animation: 0 !important;
  }

  #wrapper {
    width: 100% !important;
  }

  nav {
    width: 100% !important;
    left: auto !important;
  }

  .flex-control-nav {
    bottom: -30px;
  }
}


body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

main {
  height: 100vh;
  height: -webkit-fill-available;
  max-height: 100%;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.dropdown-toggle {
  outline: 0;
}

.btn-toggle {
  padding: 0.25rem 0.5rem;
  font-weight: 600;
  color: var(--color-bright-blue);
  background-color: transparent;
  text-align: left;
}

.btn-toggle:hover,
.btn-toggle:focus {
  color: #0eb0e5;
  background-color: #010811;
}

.btn-toggle::before {
  width: 1.25rem;
  line-height: 0;
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%28137,102,47,.95%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
  transition: transform 0.35s ease;
  transform-origin: 0.5rem 50%;
}

[data-bs-theme="dark"] .btn-toggle::before {
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%28137,102,47,.95%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
}

.btn-toggle[aria-expanded="true"] {
  color: rgba(var(--bs-emphasis-color-rgb), 0.85);
}

.btn-toggle[aria-expanded="true"]::before {
  transform: rotate(90deg);
}

.btn-toggle-nav a {
  padding: 0.1875rem 0.5rem;
  margin-top: 0.125rem;
  margin-left: 1.25rem;
}

.btn-toggle-nav a:hover,
.btn-toggle-nav a:focus {
  background-color: var(--bs-tertiary-bg);
}

.scrollarea {
  overflow-y: auto;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.btn-bd-primary {
  --bd-violet-bg: #712cf9;
  --bd-violet-rgb: 112.520718, 44.062154, 249.437846;
  --bs-btn-font-weight: 600;
  --bs-btn-color: var(--bs-white);
  --bs-btn-bg: var(--bd-violet-bg);
  --bs-btn-border-color: var(--bd-violet-bg);
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: #6528e0;
  --bs-btn-hover-border-color: #6528e0;
  --bs-btn-focus-shadow-rgb: var(--bd-violet-rgb);
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: #5a23c8;
  --bs-btn-active-border-color: #5a23c8;
}

.bd-mode-toggle {
  z-index: 1500;
}

/* new for scrub submit box */
.add-phone-number {
  border-radius: 2rem;
  width: 94%;
  height: 45px;
  margin-top: 0.8rem;
  color: white;
  background-color: var(--color-interior-field);
  position: absolute;
  border: 1px solid #0a8cb7;
  outline: none;
}

.quickscrub-phonebox {
  position: relative;
  top: 1px;
  left: 1rem;
  height: 110px;
  border-radius: .5rem;
  border: 1px solid #0a8cb7;
  background: var(--color-cardbox);
  padding: 0.75rem !important;
  border-left-width: 0.65rem;
  /*width:98%!important;*/
}


.quickscrub-placeholder-copy {
  color: #7c8a99;
  font-size: 1rem;
  font-style: italic;
  margin-left: 1rem;
  margin-top: 0.2rem;
  width: 80%;
  line-height: 1.2;
}

button.quickscrub-number-submit-flex {
  z-index: 2;
  border: none;
  height: 30px;
  cursor: pointer;
  transform: translateX(-6.5px);
  background-color: transparent;
}

.sidenav-slider-uploadlist-toprow {
  margin-left: 1%;
}

.sidenav-divider {
  margin-top: 0.01rem !important;
  margin-bottom: 0.01rem !important;
}

.username-account {
  margin-top: 1rem;
  font-size: 0.7rem;
}

.offcanvas-body,
.offcanvas-title,
.offcanvas-header {
  background-color: #131F2C !important;
}


.currentview {
  color: #709CAF;
}

.offcanvas-header button.btn-close,
.bi-x-square {
  color: #709CAF !important;

}

.bi-x-square:hover {
  color: goldenrod !important;
}

.marg16 {
  margin-top: 1rem;
}

.quickscrub-title {
  color: var(--color-card-font);
}

/* responsive media queries */

/* responsive  xxl */
@media screen and (min-width: 1440px) {
  button.quickscrub-number-submit {
    margin-top: calc(4% - 16px) !important;
    margin-left: calc(90% - 12px) !important;
  }

  .upload-list {
    font-size: 18px;
  }

  .user-status-icon {
    position: relative;
    /*left: 8px;*/
    top: 1px;
  }

  .logout-mobile {
    font-size: 1rem;
  }
}

.center-logout {
  position: relative;
  text-align: center;
}

/* responsive  xl */
@media screen and (max-width: 1200px) {
  .animated {
    -webkit-animation: 0 !important;
    animation: 0 !important;
  }

  #wrapper {
    width: 100% !important;
  }

  nav {
    width: 100% !important;
    left: auto !important;
  }

  .clients div span {
    float: none;
    display: table;
    margin: auto;
  }
}

/* responsive  base lg */
@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .status-card-generate {
    max-width: 100%;
  }

  button.quickscrub-number-submit {
    right: 18px !important;
    top: 45px !important;
  }

  .logout-mobile {
    /* margin-left: 1rem !important;
      margin-top: 1.2rem;*/
    font-size: 1rem;
  }

  .top-menu-spacer {
    margin-left: -.2rem;
  }

}

/* responsive  base lg to xl */
@media screen and (min-width: 992px) and (max-width: 1200px) {
  button.quickscrub-number-submit {
    position: absolute;
    right: 16px;
    z-index: 2;
    border: none;
    top: 90px;
    height: 30px;
    cursor: pointer;
    transform: translateX(2px);
    background-color: transparent;
  }

  .footer {
    height: auto;
  }

  .user-status-icon {
    position: relative;
    /*left: -32px;*/
    top: 8px;
  }

  .username-account {
    margin-top: 1.6rem;
    font-size: 0.7rem;
    margin-left: -2.3rem;
  }

  .quickscrub-phonebox {
    position: relative;
    top: 1px;
    width: 48%;
  }

  .card {
    width: 98%;
  }
}

/* responsive  base almost-lg */
@media screen and (max-width: 991px) {

  .menu ul li:hover ul,
  .menu ul li li:hover ul {
    display: none;
  }

  .menu ul li {
    float: none;
  }

  .menu ul li a {
    padding: 15px 20px;
    font-weight: 300;
    color: #fff;
    font-size: 16px !important;
  }

  .menu li a:before {
    display: none;
  }

  .menu li:hover {
    background: rgba(255, 248, 213, 0.25);
  }

  .menu li:hover a {
    color: #fff !important;
  }

  .menu li.current a {
    color: #fff;
  }

  .menu li.current {
    background: rgba(255, 248, 213, 0.25);
  }

  .menu ul li ul {
    visibility: visible;
    opacity: 1;
    position: relative;
  }

  .menu ul ul li {
    width: 240px;
  }

  .menu ul li ul li a {
    padding: 15px 20px;
    font-weight: 300;
    color: #fff !important;
    font-size: 16px;
    border-bottom: none !important;
    border-top: none !important;
  }

  .menu ul li ul {
    box-shadow: none;
    border-top: none;
  }

  .menu ul li ul li a:hover {
    color: #fff !important;
  }

  .menu ul ul {
    background: #00c0e1;
    color: #fff;
  }

  .menu ul li ul li a:hover {
    background: rgba(0, 0, 0, 0.25);
  }

  .menu ul ul ul {
    margin-top: 0px !important;
    left: 0px;
  }

  .menu ul li ul li ul {
    visibility: visible !important;
  }

  .footer {
    height: auto;
  }
}

/* responsive  base md-lg */
/* tablets phones */
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .quickscrub-phonebox {
    position: relative;
    top: 1px;
    height: 110px;
    border-radius: 8px;
    border: 1px solid #0a8cb7;
    background: var(--color-cardbox);
    padding: 0.75rem !important;
  }

  .add-project {
    max-width: 736px;
    width: 100%;
  }

  .navbar-toggler-icon,
  .navbar-toggler-icon:hover {
    border: none;
    background-color: #0eb0e5 !important;
  }


  p.upload-copy {
    width: 100%;
  }

  #menu-btn {
    position: sticky;
  }

  .logo {
    position: relative;
    max-width: 120px;
    margin-bottom: .31rem;
    margin-top: .2rem;
  }

  .dropdown,
  .dropdow-item {
    width: 90% !important;
  }

  .status-bar {
    font-size: .89rem;
  }

  .status-importing,
  .status-completed,
  .status-scrubbing,
  .status-generating {
    font-weight: 600;
  }

  .status-generating {
    margin-left: -1rem;
  }

  .status-icon {
    font-size: .7rem;
    font-weight: 600;
  }

  .status-filename {
    /* font-size: .7rem;*/
    margin-top: -0.41em;
  }

  .submit-button-icon {
    left: 7.8rem;
  }

  .project-name {
    margin-left: -7em;
  }

  .searchbox-fixed {
    position: relative;
    background: #131F2C;
    padding-bottom: 2rem;
    width: 330px;
    height: 150px;
    margin-bottom: 1.8rem;
  }

  .navbar-toggler {
    border: none;
    color: #0eb0e5 !important;
    margin: 1rem;
    background-color: #0eb0e5 !important;
    margin-right: 2rem !important;
  }

  nav.navbar.sub-nav.px-5 {
    top: 5.5rem;
  }

  h1.dns-pagetitle {
    margin-top: 2.2em;
  }

  .quickscrub-phonebox {
    left: 0 !important;
  }

  .card {
    width: 98% !important;
  }

  .row {
    margin-right: 0;
  }

  .download-file-available,
  .download-file-unavailable {
    font-size: 0.68rem !important;
    padding: 0.1rem 2.2rem;
  }
}

/* large mobile phones to tablets*/
@media screen and (min-width: 425px) and (max-width: 767px) {
  main.container-fluid {
    --bs-gutter-x: .65rem !important;
  }

  button.quickscrub-number-submit {
    position: absolute;
    right: 20px !important;
    top: 95px !important;
  }

  .navbar-toggler {
    border: none;
    color: #0eb0e5 !important;
    margin: 1rem;
    background-color: #0eb0e5 !important;
  }

  .add-project {
    min-width: 320px;
    width: 100%;

  }

  .logout-mobile {
    margin-left: 162px !important;
    margin-top: 2rem;
    width: 300px;
  }

  .dropdown,
  .dropdow-item,
  .navbar-collapse {
    width: 90% !important;
  }

  .row>* {
    padding-right: calc(var(--bs-gutter-x) * 0) !important;
  }

  span.navbar-toggler-icon {
    background-color: #0eb0e5 !important;
    width: 1.2rem;
    margin-left: 0;
  }

  .menu-btn {
    margin-top: -80px !important;
  }

  .logo {
    position: relative;
    margin-top: 1rem;
    margin-bottom: .51rem;
  }

  .nav-link,
  .dropdown-item,
  .status-icon-position {
    font-size: 1rem;
  }

  .mobile-marg25 {
    margin-top: 25px;
  }

  .logout-mobile {
    margin-left: 165px !important;
    margin-top: 2rem;
  }

  .nav-padding {
    margin-left: 0;
  }

  .dropdown,
  .dropdow-item,
  .navbar-collapse {
    width: 90% !important;
  }

  .card-body {
    font-size: 1em !important;
  }

  .status-generate,
  .status-queued,
  .status-importing,
  .status-scrubbing {
    font-size: 1.3em;
  }

  .status-queued {
    margin-top: -.5rem;
  }

  .not-status {
    line-height: 2rem;
  }

  .border-bottom {
    border-bottom: var(--bs-border-width) var(--bs-border-style) var(--color-border) !important;
    padding-bottom: 1rem;
  }

  .download-file-unavailable {
    margin-top: 1rem;
  }

  .download-history {
    font-size: 1rem;
    display: block;
    margin: 0;
    margin-top: 1rem;
  }

  .delete-top-margin {
    display: block;
    margin-top: 1.2rem;
    margin-left: 47%;
  }

}

/* large mobile phones */
@media (max-width: 425px) {

  .row>* {
    padding-right: calc(var(--bs-gutter-x) * 0) !important;
  }

  main.container-fluid {
    --bs-gutter-x: .0 !important;
  }

  h1.dns-pagetitle {
    font-size: 1.5rem !important;
  }

  .footer {
    margin-top: 50px;
  }

  .card {
    width: 95%;
  }

  button.upload-list {
    margin-left: .5rem;
    width: 90%;
  }

  ol.breadcrumb {
    width: 100%;
  }

  .page-top {
    padding-bottom: 4rem;
    text-align: right;
    margin-right: 0.5rem;
    padding-top: 4rem;
  }

  .scroll-to-top-cta {
    left: 88%;
    position: relative;
  }

  .footer h5 {
    left: 0;
  }

  span.navbar-toggler-icon {
    background-color: #0eb0e5 !important;
    width: 2rem;
  }

  .menu-btn {
    margin-top: -80px !important;
  }

  .logo {
    position: relative;
    margin-top: 1rem;
    margin-bottom: .51rem;
    /*max-width: 10rem;*/
  }

  .nav-link,
  .dropdown-item,
  .status-icon-position {
    font-size: 1rem;
  }

  .mobile-marg25 {
    margin-top: 1.7rem;
  }

  .mobile-marg16 {
    margin-top: 1rem;
  }

  .logout-mobile {
    margin-left: 165px !important;
    margin-top: 2rem;
  }

  .nav-padding {
    margin-left: 0;
  }

  .dropdown,
  .dropdow-item,
  .navbar-collapse {
    width: 90% !important;
  }

  .card-body {
    font-size: 1em !important;
  }

  .status-generate,
  .status-queued,
  .status-importing,
  .status-scrubbing,
  status-completed {
    font-size: 1.3em;
  }

  .status-queued {
    margin-top: -.5rem;
  }

  .not-status {
    line-height: 2rem;
  }

  .border-bottom {
    border-bottom: var(--bs-border-width) var(--bs-border-style) var(--color-border) !important;
    padding-bottom: 1rem;
  }

  .download-file-unavailable {
    margin-top: 1rem;
  }

  .download-history {
    font-size: 1rem;
    display: block;
    margin: 0;
    margin-top: 1rem;
  }

  .delete-top-margin {
    display: block;
    margin-top: 1.2rem;
    margin-left: 47%;
  }

  .quickscrub-phonebox {
    width: 90%;
  }
}

/* medium mobile phones */
@media screen and (min-width: 375px) and (max-width: 424px) {

  .row>* {
    padding-right: calc(var(--bs-gutter-x) * 0) !important;
  }

  .upload-list {
    max-width: 230px;
  }

  ol {
    margin-top: 1rem;
  }

  ol.breadcrumb {
    width: 104% !important;
    max-width: 425px;
  }

  .quickscrub-number-submit {
    position: absolute;
    right: 15px !important;
    top: 93px !important;
  }

  .button.navbar-toggler {
    /* margin-left: 120px !important;*/
    border: none;
  }

  span.navbar-toggler-icon {
    background-color: #0eb0e5 !important;
    width: 2rem;
    margin-left: -0.9rem;
  }


  .logout-mobile {
    margin-left: 118px !important;
    margin-top: 2rem;
    width: 300px;
  }

  .nav-padding {
    margin-left: 0;
  }

  .dropdown,
  .dropdow-item,
  .navbar-collapse {
    width: 90% !important;
  }

  .card-body {
    font-size: 1em !important;
  }

  p.upload-copy {
    padding: 0 1.2rem;
  }
}

/* small mobile phones */
@media screen and (min-width: 310px) and (max-width: 374px) {
  .row>* {
    padding-right: calc(var(--bs-gutter-x) * 0) !important;
  }

  .quickscrub-number-submit {
    margin-top: calc(-9% - 7px) !important;
    margin-left: calc(90% - 12px) !important;
  }

  .navbar-toggler {
    /*margin-left: 80px !important;*/
    border: none;
  }

  .logout-mobile {
    margin-left: 60px !important;
    margin-top: 2rem;
    width: 300px;
  }

  .nav-padding {
    margin-left: 0;
  }

  .dropdown,
  .dropdow-item,
  .navbar-collapse {
    width: 90% !important;
  }

  .card-body {
    font-size: 1em !important;
  }
}



.offcanvas-body {
  background-color: #e1f5fc !important;
  z-index: 333 !important;
  border-right: .5rem solid #35679970;
  padding-bottom: 25%;
}

.offcanvas-title {
  color: #2C3E50 !important;
  font-weight: 300 !important;
  background-color: #e1f5fc !important;
}

.offcanvas-header {
  background-color: #e1f5fc !important;
  z-index: 333 !important;
  border-right: .5rem solid #35679970;
}

.offcanvas-header button.btn-close,
.bi-x-square {
  color: #0eb0e5 !important;
  background-color: #0eb0e5 !important;
}

.btn-close {
  margin-left: 6rem !important;
}

.divider {
  border-bottom: .15rem solid #35679970;
}

a.subnav-link:hover,
a.nav-item:active {
  background: #c0e5f4 !important;
  color: #2C3E50 !important;
  /* font-weight: 700;  */
}

i.subnav-icon:hover {
  color: #c0e5f4 !important;
  font-weight: 700;
}

.badge {
  background: #81A91C;
  margin-left: .2rem;
}

.badge:hover {
  background: goldenrod;
}

.col-2 {
  width: 18% !important;
}

.topnav-positioning {
  margin-left: 1.5rem;
  padding-right: 0.2rem;
}

.golden {
  background-color: darkgoldenrod !important;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0EB0E5;
  --bs-btn-border-color: #0EB0E5;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2C3E50;
  --bs-btn-hover-border-color: #2C3E50;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: goldenrod;
  --bs-btn-active-bg: #2C3E50;
  --bs-btn-active-border-color: #2C3E50;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #709CAF50;
  --bs-btn-disabled-border-color: #709CAF50;
}

.subnav-menu {
  color: white;
  font-size: 1.8rem;
  padding-right: 0.5rem;
}

.submenu-cta {
  margin: 1rem 0 2rem 0;
}

.submenu-header {
  bottom: 0.3rem;
  font-size: 1.2rem;
  position: relative;
  color: white;
}

.offcanvas-header button.btn-close,
.bi-x-square {
  color: #0eb0e5 !important;
  background-color: #0eb0e5;
}

.btn-close {
  margin-right: .5rem !important;
}

/* end side nav */

#menu-btn {
  background-color: var(--color-menu-cta-background);
  margin-left: 0 !important;
  font-size: 1.9rem;
  padding: 2rem;
}

.header-top-margin {
  margin-top: 8rem;
}

.menu-position {
  margin-top: 1.5rem !important;
}

main {
  float: left;
  display: block;
  z-index: 2 !important;
  height: 100%;
  align-items: left;
  text-align: left;
}

.bi-person-fill-gear {
  padding-right: 0.5rem !important;
}

.compliance-card-section,
.regulatory-top-section {
  margin-left: var(--cg-content-margin-left);
}

.nav-heading-placement {
  margin-top: 6.5rem;
  background: transparent;
}

.btn:active,
.btn:hover {
  border: none !important;
  border-color: transparent !important;
  color: goldenrod !important;
}

.nav-link.active,
.nav-pills .show>.nav-link {
  height: 80% !important;
}

.flex-container {
  display: flex;
  flex-direction: row;
}

.compliance-subnav {
  height: 6vh;
}

.glossary-header {
  font-weight: 700;
  font-family: helvetica, arial, sans-serif;
  margin-top: .5rem;
  margin-left: 1rem;
}

.disclaimer {
  margin-top: 2rem;
  margin-right: 1.5rem;
}

.regulatory-rules-info {
  margin-top: 1.2rem !important;
  margin-bottom: 1.2rem;
  width: 95%;
}

div.regulatory-rules-info li {
  list-style-type: disc;
  padding-left: 20px; /* Optional: to give some spacing for the bullet point */
}


.dncscrub-subnav {
  justify-content: center;
}

.compliance-intro .compliance-card {
  padding: 1rem;
  border: 2px solid #0A8CB7;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: block;
  background: white;
  border-radius: .5rem;
  width: 90%;
  background: aliceblue;
}

.divder {
  border: 2px solid dodgerblue !important;
}

.compliance-card-container hr {
  border: .2px solid #fff;
}

.compliance-card-title a {
  color: #0EB0E5;
}

h4,
h5,
h3,
h6 {
  color: var(--color-text) !important;
  font-weight: bold !important;
}

h4 {
  font-size: 2rem;
}

.form-check-input {
  --bs-form-check-bg: #231F20 !important;
  background-color: #9AB3CB !important;
  padding-right: 1rem !important;
  height: 1.2rem !important;
}

.submit-button {
  background-color: #86CD86;
  width: 7.5rem;
  height: 2rem;
  border-radius: .5rem;
  margin: 1.2rem;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #3ADA67;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
}

.submit-button:active {
  background-color: #228B2290;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
}

.card-text {
  width: 100%;
}

h4.compliance-card-title {
  margin-bottom: 0.5rem;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 700;
  line-height: 1.2;
  color: #2C3E50;
  font-size: 2rem;
}

h4.compliance-card-title span {
  background-image: linear-gradient(180deg, transparent 75%, #0EB0E560 0);
  background-size: 100% 90%;
  background-repeat: no-repeat;
  text-decoration: none;
  color: #356799 !important;
}

h4.compliance-card-title a:hover {
  background-image: linear-gradient(180deg, transparent 20%, gold 0);
  background-size: 100% 90%;
  background-repeat: no-repeat;
  text-decoration: none;
  color: #2C3E50 !important;
}

.footer {
  background-color: #2C3E50 !important;
}

.subnav {
  border-radius: .5rem;
  padding: .5rem;
}

.subnav a:hover {
  border-radius: .5rem;
  background: rebeccapurple;
  color: gold;
}

.subnav a:click {
  border-radius: .5rem;
  background: #2C3E50;
  color: goldenrod;
}

.navbar-spacer {
  margin-top: 7.5rem;
}


.user-identity {
  margin-left: 1.2rem;
}

.nav-link:hover,
.subnav-link:hover,
.subnav-link:focus {
  padding-left: 0.85rem !important;
  background: #131f2c !important;
  color: goldenrod !important;
}

.subnavbar {
  background: #C0E5F4;
}

#DNCScrub-navbar {
  margin-left: -1rem;
}

a.nav-link.active {
  padding-bottom: 30px !important;
}

.container-checkboxes,
.container-views {
  margin: 1rem;
}

.list-group {
  max-height: 380px;
  margin-bottom: .5rem;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

select:-internal-list-box option:checked {
  background-color: -internal-light-dark(rgb(206, 206, 206), rgb(84, 84, 84));
  color: -internal-light-dark(rgb(16, 16, 16), rgb(255, 255, 255));
}

.dropdown>input[type="checkbox"] {
  opacity: 0;
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.dropdown {
  position: relative;
}

li {
  display: list-item;
  text-align: match-parent;
}

/* light dropdown */
.form-select {
  border: 2px solid #0A8CB7;
}

a.form-select:hover {
  color: #121F2D !important;
  background-color: #C0E5F4 !important;
  font-weight: 700;
}

.dropdown-menu {
  border: 2px solid #0A8CB7;
}

a.dropdown-item:hover {
  color: #121F2D !important;
  background-color: #C0E5F4 !important;
  font-weight: 700;
}

a.dropdown-item {
  --bs-dropdown-link-active-bg: #0A8CB7 !important;
  --bs-dropdown-link-active-color: #C0E5F4 !important;
}

.dropdown>input[type="checkbox"]:checked+a[data-toggle="dropdown"]:before {
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
  -moz-transition: -moz-transform 0.6s;
  -o-transition: -o-transform 0.6s;
  -webkit-transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
}

.dropdown-selections {
  border-radius: 8px;
  border: 1px solid #0A8CB7;
  background: #FFF;
  width: 20.5rem;
  height: 2rem;
  padding-left: 1rem;
  text-align: left;
}

.form-check-input[type=checkbox] {
  border-radius: 0.25em;
  border: 1px solid gray;
}

.comparison-header {
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 1rem;
}

.regulatory-title-subheader-section {
  text-align: left;
  border: 3px solid #2B3A4A;
  background: #2B3A4A;
  color: white;
}

.regulatory-title {
  color: white;
  font-size: 1.2rem;
  font-weight: 500;
  margin-top: .7rem;
}

.back-to-top {
  text-align: right;
  margin-top: -2rem;
  margin-right: 2rem;
  color: white;
  text-decoration: none;
}

.compare-across-states {
  text-align: right;
  padding-right: 5%;
}

.compare-across-states a {
  text-decoration: underline;
}

.regulatory-guide-content,
.regulatory-title {
  font-family: helvetica, arial, sans-serif !important;
  margin-right: 1.5rem;
}

.list-group-item.active {
  z-index: 2;
  /*color: var(--bs-list-group-active-color);
background-color: var(--bs-list-group-active-bg);
border-color: var(--bs-list-group-active-border-color);*/
  background-color: #0EB0E5;
  border-color: #0EB0E5;

}

.list-group-item.active:hover {
  z-index: 2;
  color: #121F2D;
  background-color: #C0E5F470;
  border-color: #0EB0E5;
  font-weight: 500;
}

.form-check {
  margin-bottom: 1rem;
}

.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e);
}

/* mobile */
/* mobile */
/* mobile */
/* mobile */
/* mobile */
/* mobile */
/* mobile */
/* mobile */

/* Small devices (landscape phones, 320px and up) */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  html {
    min-height: 500vh;
  }

  .flex-container {
    margin-bottom: 2rem !important;
  }

  #navbarSupportedContent {
    margin-left: 1.2rem;
  }

  .navbar-spacer {
    margin-top: 5rem;
  }

  .compliance-card {
    /*padding-bottom: 12rem!important;*/
    background: white;
  }

  h4.compliance-card-title {
    font-size: 1.5rem !important;
  }

  .user-identity {
    margin-left: 1.2rem;
  }

  .disclaimer {
    margin-top: 0.12rem;
    width: 92%;
  }

  .activated {
    margin-left: 1.2rem;
  }

  .header-top-margin {
    margin-top: 5rem;
  }

  #page-sidenav {
    visibility: hidden;
  }


  .compliance-card-section,
  .regulatory-top-section {
    margin-left: 2%;
  }

  .row {
    --bs-gutter-x: .65rem !important;
  }

  button.btn-close {
    margin-left: 5rem !important;
  }

  .subnav-link {
    font-size: 1.5rem;
  }

  .comparison-header {
    font-size: 1.2rem;
    font-weight: 500;
    margin-top: 1rem;
  }

  .regulatory-top-section {
    margin-top: 2%;
  }

  .back-to-top {
    text-align: right;
    margin-top: -2rem;
    margin-right: 3rem !important;
  }

  .regulatory-guide-content {
    margin-right: 2rem !important;
  }

  .compare-across-states {
    padding-right: 15%;
  }

  .dropdown-menu {
    border: none;
  }
}






/* Medium devices (tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px) {

  #navbarSupportedContent {
    margin-left: 1.2rem;
  }

  .navbar-top {
    margin-top: 5rem;
  }

  h4.compliance-card-title {
    font-size: 1rem !important;
  }

  hr {
    margin: 0 !important;
  }

  .activated {
    margin-left: 1.2rem;
  }

  .navbar-spacer {
    margin-top: 5rem;
  }

  .flex-container {
    margin-bottom: 2rem !important;
  }

  .header-top-margin {
    margin-top: 5rem;
  }

  #page-sidenav {
    visibility: hidden;
  }

  .compliance-card-section,
  .regulatory-top-section {
    margin-left: 2%;
  }

  .row {
    --bs-gutter-x: .65rem !important;
  }

  .regulatory-top-section {
    margin-top: 2%;
  }

  .navbar-toggler {
    margin-right: 4rem !important;
  }

  .form-select {
    position: relative;
    right: 1em;
  }

  .disclaimer {
    width: 92%;
  }

  .list-group {
    max-height: 34rem;
  }

  .dropdown {
    margin-top: 2rem;
  }

  .back-to-top {
    margin-right: 3rem;
  }
}




/* Large devices (desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .flex-container {
    margin-bottom: 2rem !important;
  }

  .bi-journal-medical {
    display: none;
  }

  h4.compliance-card-title {
    font-size: 1.5rem;
  }

  .nav-position-tablet {
    margin-top: 1.2rem;
  }

  .header-top-margin {
    margin-top: 8.8rem;
  }

  .col-2 {
    width: 25% !important;
  }

  .compliance-card-section,
  .regulatory-top-section {
    margin-left: var(--cg-content-margin-left);
  }

  .topnav-positioning {
    margin-left: .5rem;
    padding-right: 0.2rem;
  }

  #sideNavMenu {
    display: none;
  }

  .regulatory-top-section {
    margin-top: 5%;
  }

  .regulatory-top-section {
    height: 250vh;
  }

  .disclaimer {
    width: 92%;
  }

}

/* X-Large devices (large desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  #sideNavMenu {
    display: none;
  }

  .regulatory-top-section {
    margin-top: 2%;
  }

  .list-group {
    max-height: 580px;
  }

}

/* XX-Large devices (larger desktops, 1400px and up) */
@media only screen and (min-width: 1400px) {
  .nav-heading-placement {
    margin-top: 8rem !important;
    margin-left: 2rem;
    background-color: white;
  }

  .col-2 {
    width: 18% !important;
  }

  .regulatory-top-section {
    margin-top: 3%;
  }

  #sideNavMenu {
    display: none;
  }

  .list-group {
    max-height: 580px;
  }
}

/* XXXX-Large devices (larger desktops, 2400px and up) */
@media only screen and (min-width: 2400px) {
  .nav-heading-placement {
    margin-top: 8rem !important;
    margin-left: 2rem;
    background-color: white;
  }

  .col-2 {
    width: 12% !important;
  }

  .compliance-card-section,
  .regulatory-top-section {
    margin-left: var(--cg-content-margin-left) !important;
  }

  #sideNavMenu {
    visibility: hidden;
  }

  .regulatory-top-section {
    margin-top: 2%;
  }

  .list-group {
    max-height: 600px;
  }

}

#navbar {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}


#page-sidenav {
  background-color: #B8D0D950;
  z-index: 333 !important;
  border-right: .5rem solid #35679970;
  height: 100vh !important;
}


.pagetitle {
  text-align: left;
  font-weight: bold;
  font-size: 2rem;
  margin-top: 1.2rem;
  color: #232F3E;
}

section {
  width: 100%;
  height: 100%;
}

#information-section {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  flex-direction: row;
  margin-top: 6rem;

  text-align: left;
}


.glossary-card-body {
  padding: 2rem;
  text-align: left !important;
}


.btn {
  color: lightblue;
}

a.btn:active {
  color: gold;
}

a.btn:hover {
  color: goldenrod;
}

footer {
  background-color: midnightblue;
  width: 100vw;
  margin: 0;
  padding: .71rem;
}

.footer-behavior {
  z-index: 23;

}

.footer h5 {
  left: 2rem;
  position: relative;
  font-size: 1rem;
  margin: .5rem;
  padding: .71rem;
  color: whitesmoke;
}

#project-section-header {
  text-align: center;
}

.side-menu-title {
  text-align: left;
  font-weight: bold;
  font-size: 25px;
  color: green;
  margin-left: 1rem;
}

.side-menu-glossary {
  text-align: left;
  margin: 1rem;
}

.projects-grid {
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: space-around;
}

.projects-grid>a {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

#project-img {
  width: 400px;
  height: 300px;
}

#contact-header {
  text-align: center;
}

#links {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.offcanvas-body {
  background-color: #e1f5fc !important;
  z-index: 333 !important;
  border-right: .5rem solid #35679970;
  height: 100vh !important;
}

.offcanvas-title {
  color: #2C3E50 !important;
  font-weight: 300 !important;
  background-color: #e1f5fc !important;
}

.offcanvas-header {
  background-color: #e1f5fc !important;
  z-index: 333 !important;
  border-right: .5rem solid #35679970;
}

.offcanvas-header button.btn-close,
.bi-x-square {
  color: #0eb0e5 !important;
  background-color: #0eb0e5 !important;
}

.btn-close {
  margin-left: 6rem !important;
}

.divider {
  border-bottom: .15rem solid #35679970;
}

a.subnav-link:hover,
a.nav-item:active {
  background: #c0e5f4 !important;
  color: #2C3E50 !important;
  /* font-weight: 700; */
}

i.subnav-icon:hover {
  color: #2C3E50 !important;
  font-weight: 700;
}

.badge {
  background: #81A91C;
  margin-left: .2rem;
}

.badge:hover {
  background: goldenrod;
}

.col-2 {
  width: 18% !important;
}

.topnav-positioning {
  margin-left: 1.5rem;
  padding-right: 0.2rem;
}

.golden {
  background-color: darkgoldenrod !important;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0EB0E5;
  --bs-btn-border-color: #0EB0E5;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2C3E50;
  --bs-btn-hover-border-color: #2C3E50;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: goldenrod;
  --bs-btn-active-bg: #2C3E50;
  --bs-btn-active-border-color: #2C3E50;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #709CAF50;
  --bs-btn-disabled-border-color: #709CAF50;
}

.subnav-menu {
  color: white;
  font-size: 1.8rem;
  padding-right: 0.5rem;
}

.submenu-cta {
  margin: 1rem 0 2rem 0;
}

.submenu-header {
  bottom: 0.3rem;
  font-size: 1.2rem;
  position: relative;
  color: white;
}

.offcanvas-header button.btn-close,
.bi-x-square {
  color: #0eb0e5 !important;
  background-color: #0eb0e5;
}

.btn-close {
  margin-right: .5rem !important;
}

/* end side nav */

#menu-btn {
  background-color: var(--color-menu-cta-background);
  margin-left: 0 !important;
  font-size: 1.9rem;
  padding: 2rem;
}

.header-top-margin {
  margin-top: 8rem;
}

.menu-position {
  margin-top: 1.5rem !important;
}

main {
  float: left;
  display: block;
  z-index: 2 !important;
}

.bi-person-fill-gear {
  padding-right: 0.5rem !important;
}

.compliance-card-section {
  margin-left: var(--cg-content-margin-left);
}

.nav-heading-placement {
  margin-top: 6.5rem;
  background: transparent;
}

.btn:active,
.btn:hover {
  border: none !important;
  border-color: transparent !important;
  color: goldenrod !important;
}

.nav-link.active,
.nav-pills .show>.nav-link {
  height: 80% !important;
}

.flex-container {
  display: flex;
  flex-direction: row;
}

.compliance-subnav {
  height: 6vh;
}

.glossary-header {
  font-weight: 700;
  font-family: helvetica, arial, sans-serif;
  margin-top: .5rem;
  margin-left: 1rem;
}

.disclaimer {
  margin-top: 2rem;
}

.dncscrub-subnav {
  justify-content: center;
}

.compliance-intro .compliance-card {
  padding: 1rem;
  border: 2px solid #0A8CB7;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: block;
  background: white;
  border-radius: .5rem;
  width: 90%;
}

.divder {
  border: 2px solid dodgerblue !important;
}

.compliance-card-container hr {
  border: .2px solid #fff;
}

.compliance-card-title a {
  color: #0EB0E5;
}

.card-text {
  width: 100%;
}

h4.compliance-card-title {
  margin-bottom: 0.5rem;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 700;
  line-height: 1.2;
  color: #2C3E50;
  font-size: 2rem;
}

h4.compliance-card-title span {
  background-image: linear-gradient(180deg, transparent 75%, #0EB0E560 0);
  background-size: 100% 90%;
  background-repeat: no-repeat;
  text-decoration: none;
  color: #356799 !important;
}

h4.compliance-card-title a:hover {
  background-image: linear-gradient(180deg, transparent 20%, gold 0);
  background-size: 100% 90%;
  background-repeat: no-repeat;
  text-decoration: none;
  color: #2C3E50 !important;
}

/* Next two classes are so the space after the icon and FAQ does not have underline */
.compliance-card-title a {
  text-decoration: none;
}
.compliance-card-title a span {
  text-decoration: underline;
}

.footer {
  background-color: #2C3E50 !important;
}

.subnav {
  border-radius: .5rem;
  padding: .5rem;
}

.subnav a:hover {
  border-radius: .5rem;
  background: rebeccapurple;
  color: gold;
}

.subnav a:click {
  border-radius: .5rem;
  background: #2C3E50;
  color: goldenrod;
}

.navbar-spacer {
  margin-top: 7.5rem;
}

.user-identity {
  margin-left: 1.2rem;
}

.nav-link:hover {
  background: #131f2c !important;
  color: goldenrod !important;
}

.subnav-link:hover,
.subnav-link:focus {
  padding-left: 0.85rem !important;
  background: #C0E5F4 !important;
  color: #131F2C !important;
}

.subnavbar {
  background: #C0E5F4;
}

#DNCScrub-navbar {
  margin-left: -1rem;
}

a.nav-link.active {
  padding-bottom: 30px !important;
}

#navbar {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

#page-sidenav {
  background-color: #B8D0D950;
  z-index: 333 !important;
  border-right: .5rem solid #35679970;
  height: 100vh !important;
}

main {
  height: 100%;
  align-items: left;
  text-align: left;
}

.pagetitle {
  text-align: left;
  font-weight: bold;
  font-size: 2rem;
  color: #232F3E;
}

section {
  width: 100%;
}

.glossary-card-body {
  padding: 2rem;
  text-align: left !important;
}

.btn {
  color: lightblue;
}

a.btn:active {
  color: gold;
}

a.btn:hover {
  color: goldenrod;
}

footer {
  background-color: midnightblue;
  width: 100vw;
  margin: 0;
  padding: .71rem;
}

.footer-behavior {
  z-index: 23;
}

.footer h5 {
  left: 2rem;
  position: relative;
  font-size: 1rem;
  margin: .5rem;
  padding: .71rem;
  color: whitesmoke;
}

#project-section-header {
  text-align: center;
}

.side-menu-title {
  text-align: left;
  font-weight: bold;
  font-size: 25px;
  color: green;
  margin-left: 1rem;
}

.side-menu-glossary {
  text-align: left;
  margin: 1rem;
}

.projects-grid {
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: space-around;
}

.projects-grid>a {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

#project-img {
  width: 400px;
  height: 300px;
}

#contact-header {
  text-align: center;
}

#links {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}


/* Small devices (landscape phones, 320px and up) */

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .flex-container {
    margin-bottom: 2rem !important;
  }

  #navbarSupportedContent {
    margin-left: 1.2rem;
  }

  .navbar-spacer {
    margin-top: 5rem;
  }

  .navbar-toggler {
    margin-right: 2rem;
  }

  .compliance-card {
    background: white;
  }

  h4.compliance-card-title {
    font-size: 1.5rem !important;
  }

  .user-identity {
    margin-left: 1.2rem;
  }

  .disclaimer {
    margin-top: 0.12rem;
    padding-right: 2rem !important;
  }

  .activated {
    margin-left: 1.2rem;
  }

  .header-top-margin {
    margin-top: 5rem;
  }

  #page-sidenav {
    visibility: hidden;
  }

  .pagetitle {
    margin-top: 0.2rem !important;
  }

  .compliance-card-section {
    margin-left: 2%;
  }

  .row {
    --bs-gutter-x: .65rem !important;
  }

  button.btn-close {
    margin-left: 5rem !important;
  }

  .subnav-link {
    font-size: 1.5rem;
  }
}

/* Medium devices (tablets, 768px and up) */

@media only screen and (min-width: 768px) and (max-width: 991px) {
  #navbarSupportedContent {
    margin-left: 1.2rem;
  }

  .navbar-top {
    margin-top: 5rem;
  }

  h4.compliance-card-title {
    font-size: 1rem !important;
  }

  hr {
    margin: 0 !important;
  }

  .activated {
    margin-left: 1.2rem;
  }

  .navbar-spacer {
    margin-top: 5rem;
  }

  .flex-container {
    margin-bottom: 2rem !important;
  }

  .header-top-margin {
    margin-top: 5rem;
  }

  #page-sidenav {
    visibility: hidden;
  }

  .pagetitle {
    margin-top: 0.2rem !important;
  }

  .compliance-card-section {
    margin-left: 2%;
  }

  .row {
    --bs-gutter-x: .65rem !important;
  }

  .states-tablet {
    position: relative;
    right: 2rem;
  }
}

/* Large devices (desktops, 992px and up) */

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .flex-container {
    margin-bottom: 2rem !important;
  }

  .bi-journal-medical {
    display: none;
  }

  h4.compliance-card-title {
    font-size: 1.5rem;
  }

  .nav-position-tablet {
    margin-top: 1.2rem;
  }

  .header-top-margin {
    margin-top: 8.8rem;
  }

  .col-2 {
    width: 25% !important;
  }

  .compliance-card-section {
    margin-left: var(--cg-content-margin-left) !important;
  }

  .topnav-positioning {
    margin-left: .5rem;
    padding-right: 0.2rem;
  }

  #sideNavMenu {
    display: none;
  }

  .list-group {
    max-height: 540px;
  }

  .form-select {
    margin-top: 2rem;
  }
}

/* X-Large devices (large desktops, 1200px and up) */

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  #sideNavMenu {
    display: none;
  }
}

/* XX-Large devices (larger desktops, 1400px and up) */

@media only screen and (min-width: 1400px) {
  .nav-heading-placement {
    margin-top: 8rem !important;
    margin-left: 2rem;
    background-color: white;
  }

  .col-2 {
    width: 18% !important;
  }

  .compliance-card-section {
    margin-left: var(--cg-content-margin-left) !important;
  }

  #sideNavMenu {
    display: none;
  }
}

/* XXXX-Large devices (larger desktops, 2400px and up) */

@media only screen and (min-width: 2400px) {
  .nav-heading-placement {
    margin-top: 8rem !important;
    margin-left: 2rem;
    background-color: white;
  }

  .col-2 {
    width: 12% !important;
  }

  .compliance-card-section {
    margin-left: var(--cg-content-margin-left) !important;
  }

  #sideNavMenu {
    visibility: hidden;
  }
}

/* Use a fixed width for the CG Subnav vs a col-2 so it doesn't change when thev viewport widens */
.compliance-guide-subnav {
  width:  var(--cg-navbar-width);
}

a.current-page {
  background: #9ab3cb !important;
  font-weight: 500;
  color: black !important;
}

h1, h2, h3, h4, h5, h6 {
  color:var(--color-text) !important;
}

h4 {
  font-size: 2rem !important;
}

.regulatory-title-subheader-section {
  text-align: left;
  border: 3px solid #2B3A4A;
  background:#2B3A4A;
  color:white;
}  

.regulatory-title {
 color:white !important;
 font-size:1.2rem;
 font-weight:700;
 margin-top:.7rem;
}

.regulatory-contents {
  margin-left: 20px !important;
 }

.content-width-table {
  width: auto;
  display: inline-block;
}

.customBtn {
  padding-left: 0.5rem; 
  padding-right: 0.5rem;
  background-color: #f0f0f0;
}
.customBtn:hover {
  background-color: darkgray;
}

body {
  margin: 0;
  padding: 0.5rem 0 0 0;  /* This ensures content starts below the border */
  position: relative;  /* This ensures the ::before pseudo-element is relative to the body */
}

body::before {
  content: "";
  position: fixed;  /* This keeps the border fixed at the top */
  top: 0;
  left: 0;
  right: 0;  /* These three properties (top, left, right) ensure the border stretches across the top */
  height: 0.5rem;
  background-color: #35679970;  /* Using background-color to simulate the border effect */
  z-index: 1000;  /* This keeps the border above other content, adjust if necessary */
}

/* Added so navbar has vertical scroll */
.compliance-guide-subnav nav {
  max-height: 100vh;  
  overflow-y: auto;   
}

/* This is temporary until we refactor FC's final CSS
  change the button text color to not be orange
  when we mouse over it
  It is not a color in our we want to have when CG is
  embedded within the Tcl app
  */
 .btn-secondary:active, .btn-secondary:hover {
    border: var(--bs-btn-border-width) solid var(--bs-btn-border-color) !important; 
    color: white !important;
}