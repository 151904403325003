.backToTop {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #000;
    color: #fff;
    padding: 10px 20px;
    border-radius: 50px;
    cursor: pointer;
    z-index: 1000;
    transition: background-color 0.3s;
}

.backToTop:hover {
    background-color: #333;
}
