td, th > span.adjust-w {
    white-space: normal;
    word-wrap: break-word; 
    overflow-wrap: break-word;
    max-width: 450px; 
  }
.chartOption {
    color: black;
    background-color: #9AB3CB !important;
    padding: 10px;
    margin-top: 5px;
    cursor: pointer;
    border-radius: 3px;
   border: solid 1px #0d6efd;
   
}