body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.table {
  width: 100%;
  border-collapse: collapse; /* Make sure table borders are collapsed */
}
.table th, .table td {
  border: 1px solid #00659d;
  padding: 8px;
  text-align: left;
}
.table th {
  text-align: center;
}
.react-datepicker-wrapper{
  width: 100% !important;
}
.loader-container {
  position: fixed;       /* Fixed positioning to cover entire viewport */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-left: 250px;
  background-color: rgba(255, 255, 255, 0.8);   /* Background with 80% opacity */
  display: flex;        /* Flexbox to center the loader */
  justify-content: center;
  align-items: center;
  z-index: 100;        /* Ensures loader is above other content */
}
